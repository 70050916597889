
.chiller-theme { font-size: 0.9rem; }

.content-center{ display: flex; justify-content: center; align-items: center; }
.wrapper { display: flex; justify-content: center; align-items: center; height: 100vh;flex-direction: column; } 
.logo-container{ display: flex; justify-content: center; position: relative; top: -30px; } 
.lg-title { font-weight: 700; font-size: 14px; margin: 4px 0;}
.lg-sub-title, .lg-title { text-align: center; font-size: 11px; }
.h6, h6 { font-size: 1rem;}
 
.form-control {
    display: block;
    width: 100%;
    padding: 0.35rem 7px !important;
    margin: 5px 0;
    font-size: 13px;
    line-height: 1.5;
    font-weight: 700;
    color: #495057;
    box-sizing: border-box;
    border-radius: 0;
    background-color: #fff;
    border: 1px solid #ced4da;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 }
 
 .form-control:focus { color: #495057; background-color: #fff; border-color: #80bdff; outline: 0; box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%); }
 
 .btn {
    display: inline-block;
    font-weight: 400;
    font-size: 10px;
    text-align: center;
    font-weight: 600;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    line-height: 1.5;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 }
 
 .btn-block {
    display: block;
    width: 100%;
 }
 
 .btn-group-lg>.btn, .btn-lg {
    padding: 0.5rem 1rem;
    font-size: .785rem;
    line-height: 1.5;
    border-radius: 0.3rem;
 }
 
 .btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    cursor: pointer;
 }
 
 .forget {
    margin: 10px 0;
    font-size: 12px;
    cursor: pointer;
    text-align-last: right;
 }
 
 .forget-span{
   position: relative;
   left: 20px;
   color: #0a67a9;
   font-weight: 600;
 }
 
 .text.center {
   text-align: center;
 }
 
 .center {
   align-items: center;
 }
 
 .eye {
   width: 25px;
   height: 25px;
   position: relative;
   float: right;
   top: -32px;
   left: -9px;
 }
 
 #show svg:last-child {
     display: none;
 }
 
 #msg {
    width: 100%;
    text-align: center;
    color: #fff;
    margin-top: 10px;
    border-radius: 1px;
    padding: 11px;
    font-weight: 500;
    background: #d51104;
    font-size: 12px;
 }
 
 .copyright {
   font-size: 0.7rem;
   color: rgba(36, 28, 21, 0.65);
   line-height: 1.5;
   position: absolute;
   bottom: 15px;
 }
 