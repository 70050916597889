:root {
  --body: #ede7f6;
  --title: #202124;
  --header-bg-color: rgb(103, 58, 183);
  --header-color: rgba(255, 255, 255, 1);
  --color-error: #d93025;
  --border-size: 1.6px;
}

.login{
  width: 320px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

input[type="date"]:disabled + label,
input[type="number"]:disabled + label,
input[type="text"]:disabled + label {
  border-bottom: 2px dotted #ddd;
}

.list-count {
  font-family: Roboto, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 16px;
  color: #70757a;
  color: #202124;
  padding-top: 8px;
  margin-bottom: 20px;
}
.list {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #202124;
  background-color: #f8f9fa;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  margin: 4px 0 0;
  padding: 10px;
  cursor: pointer;
}

.list:hover,
.list:focus {
  background-color: #f1f3f4;
  outline: none;
}

textarea:disabled {
  background-color: #fff;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

.left-text {
  margin-top: 20px;
}

.space-btw {
  justify-content: space-between;
  align-items: center;
}

.mt10 {
  margin-top: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.res {
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  color: #202124;
  padding: 20px 24px 0;
}

.res-label {
  margin-right: 16px;
}

.white {
  background-color: #fff;
}

.top-border {
  border-radius: 8px;
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid #dadce0;
}

.border {
  border-top: 1px solid #ddd !important;
  padding: 12px 15px !important;
}

.tab {
  width: 100%;
  height: 34px;
  line-height: 1;
  display: flex;
  align-self: center;
  justify-content: center;
  padding: 23px;
  cursor: pointer;
}

.tab span {
  display: flex;
  align-self: center;
  justify-content: center;
}

.tab.active span:before {
  content: "";
  display: block;
  position: absolute;
  width: 65px;
  border-bottom: 4px solid var(--header-bg-color);
  margin-top: 29px;
  margin-left: 1px;
}

.viewheader .md-select {
  top: 0%;
  left: 0%;
  transform: translate(0%, 20%);
  margin: -12px 0 0 0;
  width: 220px;
}

.viewheader .md-select [type="button"] {
  border: 1px solid #ddd;
  border-radius: 4px;
}

.center {
  align-items: center;
}
.space-btw .h {
  height: 42px;
}

.sheet,
.more,
.print,
.delete,
.lt,
.gt {
  height: 30px;
  width: 30px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  margin: 5px;
  transition: background 0.3s;
}

.sheet:hover,
.more:hover,
.print:hover,
.delete:hover,
.lt:hover,
.gt:hover {
  border-radius: 50%;
  background-color: rgba(32, 33, 36, 0.039);
}

.sheet,
.more {
  margin-top: 25px;
}

.sheet {
  background-image: url(https://ssl.gstatic.com/docs/forms/qp_sprite161.svg);
  background-position: 3px -4650px;
}

.more {
  background-image: url(https://ssl.gstatic.com/docs/forms/qp_sprite161.svg);
  background-position: 3px -1634px;
  margin-right: 15px;
}

.delete {
  background-image: url(https://ssl.gstatic.com/docs/forms/qp_sprite161.svg);
  background-position: 3px -2284px;
}

.print {
  background-image: url(https://ssl.gstatic.com/docs/forms/qp_sprite161.svg);
  background-position: 3px -1037px;
}

.lt {
  background-image: url(https://ssl.gstatic.com/docs/forms/qp_sprite161.svg);
  background-position: 3px -5873px;
}
.gt {
  margin-left: 5px;
  background-image: url(https://ssl.gstatic.com/docs/forms/qp_sprite161.svg);
  background-position: 3px -1792px;
}

.toggle-response {
  padding: 15px 30px;
  margin: 10px 10px 0 11px;

  display: flex;
  flex-direction: row-reverse;
}

.toggle-response.check {
  background-color: var(--color-error);
  color: #fff;
}

.toggle-response + div {
  display: none;
}

.toggle-response.check + div {
  display: block;
  margin: 0 26px;
  border-top: 0;
  border: 1.5px solid #ddd;
  padding: 15px;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.respondant-msg {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #202124;
  font-weight: 700;
}

.pos-box {
  width: 30px !important;
  height: 30px;
  margin-right: 2px;
  text-align: center;
  font-size: 11pt;
  border: none;
  border-bottom: 1px solid #ddd;
}

.w100 {
  width: 100%;
  font-size: 11pt;
}

.no-border {
  border: 0;
}
body,
html {
  margin: 0;
  padding: 0;
  font-size: 11pt;
}

body {
  background-color: var(--body);
  font-family: "Roboto", sans-serif;
  margin: 0;
  margin-bottom: 20px;
}

label {
  line-height: 15pt;
  cursor: pointer;
}

.mt-115{
  margin-top:115px !important;
}

.App {
  min-width: 250px;
  max-width: 550px;
  margin: 0 auto;
}

.header{
  background-color: #fff;
  border-bottom: 1px solid #dadce0;
  width: 100%;
  height: 106px;
  position: fixed;
  top: 0px;
  z-index: 23;
}

.pad {
  padding: 12px 24px;
  font-size: 12pt;
  font-family: "docs-Roboto", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
}

.pad-10 {
  padding: 0.5em 0.5em 0.5em 0;
}

.viewheader {
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 24px;
  padding-top: 22px;
}

.remove-top-border {
  border-radius: 0 !important;
  border: 0;
}

.notice {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 16px;
  color: #70757a;
  color: rgba(0, 0, 0, 0.66);
  margin-top: 20px;
}

.rework-form {
  font-size: 22.1px;
  font-weight: 700;
  position: relative;
  margin: 24px 0;
  text-align: center;
  color: #0000006b;
  top: -6px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

.height {
  height: 10px;
}

.solidbg {
  background-color: var(--header-bg-color);
  color: var(--header-color);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  top: 11px;
}

.title {
  color: var(--title);
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
}

.desc {
  margin: 10px 0;
}

.asterisk,
.legend {
  color: var(--color-error);
}

.legend {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 20px;
  margin-top: 12px;
}

.question {
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 24px;
  page-break-inside: avoid;
  word-wrap: break-word;
}

.item-title {
  font-weight: 400;
}
.section-header-title {
  font-weight: 600;
}

.item-title,
.complete,
.section-header-title {
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 24px;
}

.underline {
  background-color: rgba(0, 0, 0, 0.12);
  bottom: -2px;
  height: 1px;
  left: 0;
  margin: 0 0 11px 0;
  width: 100%;
}

.desc-section {
  font-size: 11pt;
  letter-spacing: 0.3px;
  color: #202124;
  margin-top: 0;
  white-space: pre-wrap;
}

.navigation-btn {
  margin-top: 25px;
}

.progress {
  width: 184px;
  height: 10px;
  border-radius: 40px;
  background-color: rgb(103, 109, 114);
}

.percent {
  width: 33%;
  border-radius: 40px;
  height: 10px;
  background: #669df6;
}

.complete {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #202124;
  display: inline-block;
  padding: 0 12px;
}

.has-error {
  background-color: #fff;
  border-color: var(--color-error);
}

.has-error .underline {
  border-bottom: 1.6px solid var(--color-error);
}
.has-error .validate-error {
  display: block;
}

.err-icon {
  margin-right: 12px;
}

.validate-error {
  display: none;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 16px;
  color: var(--color-error);
  margin-top: 10px;
}

.wizbtn {
  border: 0;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  -webkit-user-select: none;
  user-select: none;
  letter-spacing: 0.25px;
  line-height: 36px;
  cursor: pointer;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  padding: 0 24px;
  color: var(--header-bg-color);
}

.back {
  margin-right: 14px;
}

.done {
  background-color: var(--header-bg-color);
  color: #fff;
}

.error p {
  font-size: 0.6em;
}

.error {
  background-color: #fbcfbd;
  max-width: 300px;
}

.block {
  font-size: 1.6rem;
  padding: 12px 0;
  border-radius: 2px;
  color: #241c15;
  overflow: hidden;
}

.flex {
  display: flex;
}
.row-reverse {
  flex-direction: row-reverse;
}
.spaced {
  width: 640px;
  justify-content: space-between;
}

.action-taken {
  color: #fff;
  background-color: #dc3912;
}

a,
textarea,
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5,
input[type="date"],
input[type="email"],
input[type="text"],
input[type="password"] {
  font-family: "Graphik Web", "Helvetica Neue", Helvetica, Arial, Verdana,
    sans-serif;
}

textarea {
  -webkit-appearance: none;
  appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  border: 0;
  outline: 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 11pt;
  line-height: 1.2857;
  resize: none;
  transition: 0.5s;
  width: 100%;
  padding-top: 20px;
}

select,
input[type="number"],
input[type="date"],
input[type="email"],
input[type="text"],
input[type="password"] {
  width: 100%;
  border: none;
  outline: none;
  background-color: #fff;
  font-size: 1.1em !important;
  padding: 8px 0 8px;
  display: block;
  border-radius: 0.15384615em;
  transition: 0.5s;
}

input:focus,
textarea:focus,
select:focus {
  background-color: #fff;
  /* box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%); */
  color: rgba(0, 0, 0, 0.95);
  border-color: #85b7d9;
  outline: 0;
  border-radius: 0.15384615em;
  box-shadow: 0 0 0 0 rgb(76 74 74 / 35%) inset;
  /* /#CC2C21 */
}

/*Checkbox*/

.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid var(--header-bg-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid var(--header-bg-color);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* input */
.ttext + label {
  display: block;
  border: none;
  outline: none;
  width: 100%;
  transform-origin: center center;
  position: relative;
  top: 3px;
  border-bottom: var(--border-size) solid #dadce0;
}

.question.has-error .ttext + label {
  border-bottom: var(--border-size) solid #d93025;
}

.ttext:focus + label {
  border-bottom: var(--border-size) solid rgb(103, 58, 183);
  animation: inputscale 0.3s;
}

.question.has-error .ttext:focus + label {
  border-bottom: var(--border-size) solid #d93025;
}

@keyframes inputscale {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

/* radio */
.m-10 {
  margin: 20px 0;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked + label:before {
  border: 3px solid var(--header-bg-color);
}

[type="radio"]:not(:checked) + label:before {
  border: 3px solid #666;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 13px;
  height: 13px;
  background: var(--header-bg-color);
  position: absolute;
  top: 5.5px;
  left: 5.5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

@media (min-width: 300px) {
  .progress {
    width: 80px;
  }
  .tab{
    padding:23px 0;
  }
}

@media (max-width: 400px) {
  .navigation-btn {
    flex-direction: column;
  }

  .notice {
    text-align: center;
  }

  .dist {
    margin-top: 20px;
  }
  .App {
    margin: 0 10px;
  }
}

/* select */

.md-select {
  /*Demo css do not add to your project*/
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 20%);
  display: block;
  margin: 2px 0 8px 0;
  padding-bottom: 2px;
  position: relative;
  font-size: 11pt;
}
.md-select *,
.md-select :after,
.md-select :before {
  box-sizing: border-box;
}
.md-select [type="button"] {
  cursor: pointer;
  background: #fff;
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 0 0 1px 0;
  color: rgba(0, 0, 0, 0.73);
  display: flex;
  line-height: 48px;
  font-size: 11pt;
  position: relative;
  text-align: left;
  text-shadow: none;
  width: 100%;
  z-index: 1;
  outline: none;
  overflow: hidden;
  height:35px;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.md-select [type="button"]:focus,
.md-select [type="button"]:hover {
  background: rgba(0, 0, 0, 0.001);
}
.md-select [type="button"]:after {
  content: "▾";
    float: right;
    position: absolute;
    padding-right: 16px;
    right: 0;
}
.md-select ul[role="listbox"] {
  background-color: white;
  cursor: default;
  list-style: none;
  line-height: 26px;
  overflow: hidden;
  margin: 0;
  max-height: 0;
  position: absolute;
  padding: 0;
  transform: translateY(-50%);
  transition: all 0.15s cubic-bezier(0.35, 0, 0.25, 1);
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
}
.md-select ul[role="listbox"] li {
  cursor: pointer;
  margin: 0;
  padding: 10px 16px;
  outline: none;
  overflow: hidden;
}
.md-select ul[role="listbox"] li:focus,
.md-select ul[role="listbox"] li:hover,
.md-select ul[role="listbox"] li.active {
  background: rgba(0, 0, 0, 0.1);
}
.md-select.active ul {
  max-height: max-content;
  width: max-content;
  overflow: auto;
  padding: 8px 0 16px 0px;
  z-index: 2;
  transition: all 0.2s ease;
}
.scope-first {
  background-color: rgba(26, 115, 232, 0.078);
  color: rgba(0, 0, 0, 0.54);
}
.bar-line {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 5px 0;
}

/* Toogle */

.checkbox {
  position: relative;
  display: inline-block;
}
.checkbox:after,
.checkbox:before {
  font-family: FontAwesome;
  font-feature-settings: normal;
  -webkit-font-kerning: auto;
  font-kerning: auto;
  font-language-override: normal;
  font-stretch: normal;
  font-style: normal;
  font-synthesis: weight style;
  font-variant: normal;
  font-weight: normal;
  text-rendering: auto;
}
.checkbox label {
  width: 90px;
  height: 32px;
  background: #ccc;
  position: relative;
  display: inline-block;
  border-radius: 46px;
  transition: 0.4s;
}
.checkbox label:after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  left: 0;
  top: -5px;
  z-index: 2;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: 0.4s;
}
.checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}
.checkbox input:hover + label:after {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2), 0 3px 8px 0 rgba(0, 0, 0, 0.15);
}
.checkbox input:checked + label:after {
  left: 40px;
}

.model-4 .checkbox label {
  background: #bbb;
  height: 15px;
  width: 45px;
}
.model-4 .checkbox label:after {
  background: #fff;
  top: -5px;
  width: 25px;
  height: 25px;
}
.model-4 .checkbox input:checked + label {
  background: rgb(225, 216, 241);
}
.model-4 .checkbox input:checked + label:after {
  background: var(--header-bg-color);
  left: 20px;
}


.date-format{
  color: rgba(0,0,0,.54);
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    font-size: 12px;
    font-style: italic;
    box-pack: end;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    padding: 0 10px 10px 0;
}

.hide-input,.x-spreadsheet-menu{
  display: none !important;
}